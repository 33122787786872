.col-cover{
    height: 100%;
    width: 100%;
    background-size: cover;
    object-fit: cover;
}
.list-card{
    background-color: #f0f0f0;
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 1.5rem;

}
.list-card a{
    color:#054f8e;

}
.crc-btn{
    background: #f37224;
    border-radius: 20px;
    color: white;
}
.mr-2{
    margin-right:5px;
}
@media (max-width: 768px){
    .list-card{
        margin-top: 15px;
        margin-right: 0;
        margin-left: 0;
    }
}
.br-2{
    border-radius: 20px;
    overflow: hidden;
}