.list-card .list-card-img{
    height: calc(15px + 100%);
    width: 100%;
    aspect-ratio: 3/2;
    border-radius: 10px;
    object-fit: cover;

}

.list-card .btn-hero{
    width: 100%;
    margin: 0;
    border-radius: 10px;
    padding: 10px;
}

.list-card .card-text {
    display: -webkit-box;
    overflow : hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;  
}

.card {
    height: 400px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    color: white;
    box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
    border-radius: 40px!important;
    margin-bottom: 2rem;
}
   
    .card-img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.9;
      transition: opacity .2s ease-out;
    border-radius: 40px;

    }
    .card-h2 {
      position: absolute;
      inset: auto auto 30px 30px;
      margin: 0;
      transition: inset .3s .3s ease-out;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: normal;
      text-transform: uppercase;
      font-weight: bold;
    }
    .card-p, .card-button {
      position: absolute;
      opacity: 0;
      max-width: 80%;
      transition: opacity .3s ease-out;
      line-height: 20px;
    }
    .card-p {
      inset: auto auto 95px 30px;
      height: 179px;
      overflow: hidden;
    }
    .card-overlay{
      position: absolute;
      height: 100%;
      width: 100%;
      inset: auto auto -300px 0px;
      background: linear-gradient(180deg, rgb(5 79 142 / 8%) 0%, rgba(5,79,142,1) 100%);
    }

    .card:hover .card-overlay{
      inset: auto auto 0px 0px;
      transition: inset .3s ease-out;
    }
    .card-button {
      inset: auto auto 20px 25px;
      color: inherit;
      text-decoration: none;
    }
    
    .card:hover .card-h2 {
      inset: auto auto 300px 30px;
      transition: inset .3s ease-out;
    }
    
    .card:hover .card-p , .card:hover .card-button {
      opacity: 1;
      transition: opacity .5s .1s ease-in;
    }
    
    .card:hover .card-img {
      transition: opacity .3s ease-in;
      opacity: 1;
    } 
  
  
  .material-symbols-outlined {
    vertical-align: middle;
  }
  