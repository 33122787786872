@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Andada+Pro:wght@400;500;600;700;800&display=swap");

/* =========== base style ========= */
:root {
  --pri:#054f8e;
  --sec:#f37224;
  --ter:#56c8f3;
}
.fc-pri{
  color: var(--pri);
}
.fc-sec{
  color: var(--sec);
}
.fc-white{
  color: white;
}
table{
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0,0,0,.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0,0,0,.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0,0,0,.075);
  border-color: #dee2e6;
  color: #212529;
  margin-bottom: 1rem;
  vertical-align: top;
  width: 100%;
}
thead{
  background-color: #054f8e;
  color: #fff!important;
  padding: 5px;
}
.gal-page .img-card::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: unset;
  border-radius: 10px;
  z-index: 1;
}
table>:not(caption)>*>* {
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  padding: .5rem;
}
.loading-holder{
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-holder-modal{
  height: 80vh;
  width: 55vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.img-card-blog-video{
  height: 250px;
}
li{
  list-style-type: none;
}
.img-card-blog-video iframe{
  width: 100%;
  height:100%;
}
ol li{
  list-style-type: inherit;
}
footer {
  padding-top: 70px;
}
.logo p{
  color:white;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 2rem;
  line-height: 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.hero__content h2 {
  font-weight: 600;
  line-height: 55px;
}

.hero__content {
  padding-top: 80px;
}
.search input {
  border: none;
  outline: none;
  padding: 7px 10%;
}

.search {
  width: 60%;
  border: 1px solid #17bf9d44;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  margin-top: 20px;
}
.slider-img{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.hero-holder{
  height: 60vh;
  position: absolute;
  bottom: 0;
  z-index: 11;
  right: 60%;
  
}
.overlay{
  height: 80vh;
  width: 100%;
  background: #004d9166;
  z-index: 1;
  position: absolute;
}
.carousel-caption h3, p{
  position: relative;
}
.cicle{
  display: flex;
  justify-content: center;
  align-items: center;
}
.cicle img{
  border:10px solid white;
  border-radius: 100px;
}
.carousel-caption{
  left: 40%!important;
  text-align: left!important;
  top:15%;
  z-index: 111;
}
.hero-header{
  font-size: 3rem;
  font-weight: bold;
  color: white;
  z-index: 999;
  position: relative;
}
.hero-sub{
  font-weight: normal;
  line-height: 30px;
  font-size: 1rem;
  color: white;
  z-index: 999;
  position: relative;
}
.btn-hero{
  display: inline-block;
  border-radius: 4px;
  background-color: #f37224;
  border: none;
  color: #FFFFFF!important;
  text-align: center;
  font-size: 17px;
  padding: 16px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  font-weight: bold;
  border-radius: 50px;
  z-index: 999;
  position: relative;
}
.btn-hero span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
 }
 
 .btn-hero span:after {
  content: '»';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -15px;
  transition: 0.5s;
 }
 
 .btn-hero:hover span {
  padding-right: 15px;
 }
 
 .btn-hero:hover span:after {
  opacity: 1;
  right: 0;
 }

 .hero-banner{
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  color: white;
 }
 a{
    z-index: 999!important;
    text-decoration: none!important;
    color: #054f8e;
 }
 .png-white {
  filter: brightness(0) invert(1);
}
@media only screen and (max-width: 1000px) {
  .four-con{
    position: relative;
    background: var(--pri);
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .abs-card::before{
    background: unset;
  }
  .cicle img{
    border: 10px solid var(--sec);
  }
}

@media only screen and (max-width: 700px) {
  .hero-header{
    font-size: 2rem;
  }
  .hero-sub{
    font-size: 1rem;
  }
}
@media only screen and (max-width: 650px) {
  .col-text{
    flex: unset!important;
    padding-bottom: 100px!important;
  }
  .hero-holder{
    right: 0;
    height: 45vh;
  }
  .hero-banner .col img{
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .hero-holder{
    height: 35vh;
  }
}



.img-card{
  position: relative;
  overflow: hidden;
  margin-bottom: 1.5rem;
  border-radius: 10px;
}
.img-card-blog{
  position: relative;
  overflow: hidden;
  margin-bottom: 1.5rem;
  border-radius: 10px;
}
.img-card-blog img{
  height: 100%;
  width: 100%;
  aspect-ratio: 2/1.5;
  object-fit: cover;
  border-radius: 10px;
  z-index: 0;
  overflow: hidden;
  transition: 0.2 all ease;
}
.clamp-5{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  font-size: 16px;
}
.img-card::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.7480453362985819) 100%);
  border-radius: 10px;
  z-index: 1;
}
.img-card img{
  height: 100%;
  width: 100%;
  aspect-ratio: 2/1.5;
  object-fit: cover;
  border-radius: 10px;
  z-index: 0;
  overflow: hidden;
  transition: 0.2 all ease;
}
.nav__item a{
  white-space: nowrap;
}

.fixed-btn{
  position: fixed;
  z-index: 99999999999999;
  background: var(--sec);
  color: white;
  font-weight: bold;
  padding: 20px;
  right: -50px;
  top: 50%;
  height: 60px;
  width: 150px;
  transform: rotate(270deg);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  animation: linear infinite;
  -webkit-animation: linear infinite;
  animation-name: run;
  animation-duration: 1s;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* @keyframes run {
  0% {
    right: -50px;
  }
  50% {
    right: calc(5% - 100px);
  }
  100% {
    right: -50px;
  }
} */
.single__testimonial img{
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 100px;
  margin-bottom: 20px;

}
.c-sec{
  color: var(--sec);
  height: 1px!important;
  font-weight: bold;
  font-size: 50px;
  opacity: 1;
}
.img-card:hover img{
  transition: 0.2 all ease;

  transform: scale(1.1);
  border-radius: 10px;
}
.img-card-text{
  position: absolute;
  bottom: 0;
  color: white;
  z-index: 3;
}
p {
  font-size: 1rem;
  line-height: 35px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
i {
  color: #17bf9e;
}
fieldset{
  border-color: rgba(255, 255, 255, 0)!important;
  border-radius: 20px;
}
.relative{
  position: relative;
  overflow: hidden;
}
.global-secondary{
  background-color: #56c8f373;
  color: black !important;
}
.counter-icon{
  width: 4rem;
  height: 4rem;
}
.footer h3{
  color:white!important;
}
 .footer a, .footer .copyright{
  color: #e1daea !important;
}
.footer a:hover{
  color: white!important;
}
.fw-5{
  font-weight: 600;
}
.slick-slide{
  background: #f0f0f0;
  padding: 30px;
  border-radius: 50px;
  border:20px solid #054f8e;
  color: black;
  /* margin: 20px; */
  /* transform: translate(-100px, 0px); */
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body {
  background: #000;
  color: #000;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
.cross{
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  margin: 30px;
  z-index: 9;
  padding: 10px;
  font-size: 4rem!important;
  border-radius: 50px;
  color: black;
  cursor: pointer;
}
.nf{
  width: 100%;
  text-align: center;
}
.box{
  background-color: #dee2e6;
  height: 100%;
  border-radius: 10px;
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 1.5rem;
  cursor: pointer;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.blog-card p{
  color: #000;
}
#demo-simple-select-label{
  padding: 10px!important;
}
.list-item-custom{
  list-style-type: disc;
  line-height: 30px;
}
.imp-li{
  display: inline-block;
}
.imp-links{
  text-align: center;
}
@media (max-width: 768px)
{
  .logo img {
      height: 40px;
  }
}